@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{

	color: white;
	font-family: 'Lato', sans-serif;
	background: gray;
}

h1{
	font-size: 1.6em;
}

h2{
	font-size: 1.3em;
}

h3{
	font-size: 1em;
}

a{
	color: white;
}

.welcome{
	padding-top:20%;
}

.content{
	@include make-row();
	text-align: center;
}

.middle{
	@include make-lg-column-offset(3);
	@include make-lg-column(6);
}

h1{
	padding-bottom: 1.6em;
}
